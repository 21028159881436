module.exports = [{
      plugin: require('/opt/build/repo/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"icon":"src/images/favicon-16x16.png","name":"Gavin Samuels Website","short_name":"Gavin Samuels","start_url":"/","background_color":"#263547","theme_color":"#e64848","display":"standalone"},
    },{
      plugin: require('/opt/build/repo/node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-151955170-1","head":true,"anonymize":true,"respectDNT":true,"pageTransitionDelay":0},
    },{
      plugin: require('/opt/build/repo/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
